export const useUtils = () => {
  const shrinkText = (text, symbolsCount) => {
    if (text && text.length > symbolsCount) {
      return text.slice(0, symbolsCount) + ' ...';
    } else {
      return text;
    }
  };

  const toLocaleDate = (str) => {
    if (!str) return '';
    return new Date(str).toLocaleDateString();
  };

  const toLocaleDateTime = (str) => {
    if (!str) return '';
    const date = new Date(str);
    return `${date.toLocaleDateString()} ${date.getHours()}:${date.getMinutes()}`;
  };

  const truncTags = (text) => {
    if (text) {
      return text
        .replace(
          /<p>|<\/p>|<b>|<\/b>|<strong>|<\/strong>|<\/span>|<span.*?>/g,
          '',
        )
        .replace(/&nbsp;/g, ' ')
        .replace(/&mdash;|&ndash;/g, '-')
        .replace(/&laquo;|&raquo;|&gt;|&lt;/g, '"');
    } else {
      return '';
    }
  };

  return {
    shrinkText,
    toLocaleDate,
    toLocaleDateTime,
    truncTags,
  };
  // Vue.filter('shrinkText', shrinkText)
  // Vue.filter('truncTags', truncTags)
  // Vue.filter('toLocaleDate', toLocaleDate)
  // Vue.filter('toLocaleDateTime', toLocaleDateTime)
};
